<div class="content">
  <ng-container *ngIf="$isAuthorized | async">
    <div class="header">
      <div class="nav-links">
        <a
          routerLink="/"
          routerLinkActive="active-link"
          class="nav-link"
          [routerLinkActiveOptions]="{exact: true}"
        >
          Ask question
        </a>

        <a
          routerLink="/predicted-questions"
          routerLinkActive="active-link"
          class="nav-link"
          [routerLinkActiveOptions]="{exact: true}"
        >
          Predicted questions
        </a>

        <a
          routerLink="/ask-predefined-question"
          routerLinkActive="active-link"
          class="nav-link"
          [routerLinkActiveOptions]="{exact: true}"
        >
          Predicted questions flow
        </a>

        <a
          routerLink="/generated-keywords"
          routerLinkActive="active-link"
          class="nav-link"
          [routerLinkActiveOptions]="{exact: true}"
        >
          Keywords
        </a>

        <a
          routerLink="/questions"
          routerLinkActive="active-link"
          class="nav-link"
          [routerLinkActiveOptions]="{exact: true}"
        >
          History
        </a>

        <a
          routerLink="/articles"
          routerLinkActive="active-link"
          class="nav-link"
          [routerLinkActiveOptions]="{exact: true}"
        >
          Articles
        </a>

        <a
          routerLink="/terms"
          routerLinkActive="active-link"
          class="nav-link"
          [routerLinkActiveOptions]="{exact: true}"
        >
          Terms
        </a>

        <ng-container
          *ngIf="($user | async) as user"
        >
          <ng-container
            *ngIf="user && user.isAdmin"
          >

            <a
              routerLink="/documents"
              routerLinkActive="active-link"
              class="nav-link"
              [routerLinkActiveOptions]="{exact: true}"
            >
              Documents
            </a>

            <a
              routerLink="/config"
              routerLinkActive="active-link"
              class="nav-link"
              [routerLinkActiveOptions]="{exact: true}"
            >
              Admin config
            </a>

            <!-- <a
              routerLink="/single-question"
              routerLinkActive="active-link"
              class="nav-link"
              [routerLinkActiveOptions]="{exact: true}"
            >
              Single question
            </a> -->
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <div
    class="content-inner"
    [ngClass]="{
      'content-inner-without-padding': !($isAuthorized | async)
    }"
    (scroll)="onScroll($event)"
  >
    <router-outlet></router-outlet>
  </div>

  <div class="footer">
    2013 - 2024, <span class="text-bold">Scharc Group - digital twins powered by AI</span>, info&#64;scharc.com
  </div>
</div>
