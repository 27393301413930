import { Component, inject, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Firestore, collection, where, query, limit, startAfter, getDocs, orderBy, getCountFromServer } from '@angular/fire/firestore';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { QueryDocumentSnapshot, Query, CollectionReference } from 'firebase/firestore';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { Article, FirestoreArticle } from 'models/article';
import { AppService } from 'app/app.service';

import { GeneratePredefinedQuestionsPayload } from './articles.component.models';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent implements OnInit, OnDestroy {
  private functions: Functions = inject(Functions);

  private firestore: Firestore = inject(Firestore);

  public articles: { id: string; data: Article }[] = [];
  
  private articlesCollection: CollectionReference;

  public articlesTotalCount: number;

  public isFetching = false;

  public cursor: QueryDocumentSnapshot<Article>;

  private subscriptions: Subscription[] = [];

  public searchForm: UntypedFormGroup | null = null;
  public isSearchFormSubmitted = false;

  public generateQuestionsIdsInProcess: string[] = [];

  constructor(
    private route: ActivatedRoute,
    private appService: AppService,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.articlesCollection = collection(this.firestore, 'articles');

    this.searchForm = new UntypedFormGroup({
      filter: new UntypedFormControl('', []),
      useKeywords: new UntypedFormControl(false, []),
    });


    const articlesQuery = query(
      this.articlesCollection,
      orderBy('dateCompleted', 'desc'),
    );

    this.fetchArticles(articlesQuery);

    this.articlesTotalCount = (await getCountFromServer(articlesQuery)).data().count;

    const appServiceSubscription = this.appService.$appScrolledToBottom.subscribe(() => {
      this.loadArticles();
    });

    this.subscriptions.push(appServiceSubscription);

    const searchFormSubscription = this.searchForm.valueChanges
      .pipe(debounceTime(500))
      .subscribe(async ({ filter, useKeywords }) => {
        const keywords = this.getKeywordsFromSearch(filter);

        let articlesQuery: Query;

        if (!keywords.length) {
          articlesQuery = query(
            this.articlesCollection,
            orderBy('dateCompleted', 'desc'),
          );
        } else {
          const whereField = useKeywords ? 'keywords' : 'titleKeywords';

          articlesQuery = query(
            this.articlesCollection,
            orderBy('dateCompleted', 'desc'),
            where(whereField, 'array-contains-any', keywords),
          );
        }

        this.articlesTotalCount = (await getCountFromServer(articlesQuery)).data().count;

        this.fetchArticles(
          articlesQuery,
          true
        );
      });

    this.subscriptions.push(searchFormSubscription);

  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public handleSearchSubmit(): void {

  }

  private getKeywordsFromSearch(value: string): string[] {
    return value.split(',')
      .map((keyword) => keyword.trim())
      .filter((keyword) => keyword)
      .map((keyword) => keyword.toLowerCase());
  }

  private loadArticles(): void {
    if (!this.cursor) {
      return;
    }

    const filterValue = this.searchForm.controls.filter.value.trim();
    const useKeywords = this.searchForm.controls.useKeywords.value;

    let queryFn: Query;

    if (filterValue) {
      const keywords = this.getKeywordsFromSearch(filterValue);

      const whereField = useKeywords ? 'keywords' : 'titleKeywords';

      queryFn = query(
        this.articlesCollection,
        orderBy('dateCompleted', 'desc'),
        startAfter(this.cursor),
        where(whereField, 'array-contains-any', keywords),
      );
    } else {
      queryFn = query(
        this.articlesCollection,
        orderBy('dateCompleted', 'desc'),
        startAfter(this.cursor),
      );
    }

    this.fetchArticles(queryFn);
  }

  private async fetchArticles(queryValue: Query, shouldClearState = false): Promise<void> {
    this.isFetching = true;

    const queryWithLimit = query(
      queryValue,
      limit(20),
    );

    const { docs } = await getDocs(queryWithLimit);

    const articles = docs.map(doc => ({
      id: doc.id,
      data: doc.data() as Article,
    }));

    if (docs.length) {
      this.cursor = docs[docs.length - 1] as QueryDocumentSnapshot<Article>;
    }

    if (shouldClearState) {
      this.articles = [...articles];
    } else {
      this.articles = [...this.articles, ...articles];
    }

    this.isFetching = false;
  }

  public async handleGenerateQuestionsClick(article: { id: string; data: Article }): Promise<void> {
    const findInDocumentsFunction = httpsCallable<
      GeneratePredefinedQuestionsPayload,
      void
      >(this.functions, 'generateArticlePredefinedQuestions');

    const payload: GeneratePredefinedQuestionsPayload = {
      id: article.id,
      articleText: article.data.article.body,
      articleTitle: article.data.article.title,
      PMID: article.data.PMID,
    };

    this.generateQuestionsIdsInProcess.push(article.id);

    const { data } = await findInDocumentsFunction(payload);

    this.generateQuestionsIdsInProcess  = this.generateQuestionsIdsInProcess.filter((id) => id !== article.id);
  }
}
