<div
  class="content-container"
>
  <div class="content-row row">
    <div class="title">
      Articles ({{articlesTotalCount || 0}})
    </div>

    <form
      [formGroup]="searchForm"
      class="row form"
    >
      <mat-form-field>
        <input
          matInput
          formControlName="filter"
          placeholder="Filter by..."
        />

      </mat-form-field>

      <mat-checkbox formControlName="useKeywords">Use keywords for search</mat-checkbox>
    </form>
  </div>

  <ng-container *ngIf="isFetching">
    <div class="overlay-container">
      <mat-spinner
        [diameter]="35"
      ></mat-spinner>
    </div>
  </ng-container>

  <ng-container *ngFor="let article of articles">
    <div class="content-row">
      <div class="row article-row">
        <a
          class="nav-link"
          [routerLink]="['/articles', article.id]"
        >
          {{article.data.article.title}}
        </a>

        <button
          *ngIf="!generateQuestionsIdsInProcess.includes(article.id)"
          mat-mini-fab
          color="primary"
          class="action-container action-button"
          title="Generate predicted questions"
          (click)="handleGenerateQuestionsClick(article)"
        >
          <mat-icon
            fontIcon="settings_backup_restore"
            class="icon"
          ></mat-icon>
        </button>
      </div>

      <div class="row article-metadata-row">
        <div>
          PMID: {{article.data.PMID}}
        </div>

        <div>
          Published date: {{article.data.dateCompleted}}
        </div>
      </div>
    </div>
  </ng-container>
</div>
